/* * {
  outline: solid 1px red;
} */
body {
  background-color: #f97316;
  background-image: url('./assets/smoke.png');
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-x: hidden;
  background-size:100%;
}


.link-active {
  background: #f97316;
  -webkit-text-fill-color: black;
  -webkit-text-stroke-color: black;
  font-weight: bold;
}


.spice{
  font: 800 50px Arial;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 2px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hut{
  font: 800 50px Arial;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.outlinedtwo {
  font: 600 35px Arial;
  -webkit-text-stroke: 1px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.slides {
  width: auto;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.slides2 {
  width: auto;
  height: 200px;
  object-fit: cover;
  object-position: center;
}


.contacts {
  position: absolute;
  top: 32%;
  left: 0px;

}

.peep {
  width: auto;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 1500px) {
  .peep {
    width: auto;
    height: 300px;
    object-fit: cover;
    object-position: center;
    align-self: center;
    
  }

  .menu{
    display: flex;
    flex-direction: column;
    align-items:center;
    
  }

}

@media screen and (max-width: 786px){
  .peep {
    width: auto;
    height: 250px;
    object-fit: cover;
    object-position: center;
    align-self: center;
    
  }

  .menu{
    display: flex;
    flex-direction: column;
    align-items:center;
    
  }

  .slides {
    width: auto;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }
  
  .contacts{
    display: none;
  }

  .spice{
    font: 800 50px Arial;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .hut{
    font: 800 50px Arial;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

}


@media screen and (max-width: 640px){
  body {
    background-color: #f97316;
    background-image: url('./assets/smoke.png');
    background-repeat: no-repeat;
    background-position: bottom;
    overflow-x: hidden;
    background-size:500%;
  }

  
  .spice{
    font: 800 50px Arial;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .hut{
    font: 800 50px Arial;
    font-weight: 700;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

}